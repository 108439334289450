<template>
  <div class="container general-info-container mb-3">
    <div>
      <details class="align-self-start w-100 text-start" :open="!sessionId">
        <summary>
          <h3 class="text-start fs-4 d-inline">General Case Info</h3>
        </summary>

        <p v-if="!sessionId" class="text-center fw-bold fs-5 ps-0 mb-0 mt-3">If necessary, fill in the blanks and change
          the incorrect data, then click "Continue"</p>

        <form id="generalInfoForm" class="inputs-grid">
          <div
              v-for="(_, key) in localInputsData"
              :key="key"
              class="d-flex flex-column">
            <label class="pb-2 ps-0 fs-5 text-start fw-medium" :for="key">
              {{ key }}
            </label>
            <input
                v-model="localInputsData[key]"
                class="p-2 fw-medium form-control"
                required
                pattern="\S+.*"
                type="text"
                :disabled="Boolean(sessionId)"
                :id="key"/>
          </div>
        </form>

        <div v-if="!sessionId" class="d-flex flex-column mt-3">
          <button form="generalInfoForm" v-if="!loading" @click="submitGeneralInfo"
                  class="btn btn-primary fw-bold fs-6 col-2 mt-3 align-self-center">Continue
          </button>
          <button v-else class="btn btn-primary fw-bold fs-6 col-2 mt-3 align-self-center" type="button" disabled>
            <span class="spinner-border spinner-border-sm me-3" aria-hidden="true"></span>
            <span role="status">Loading...</span>
          </button>
        </div>
      </details>
    </div>
  </div>
</template>

<script lang="ts">
import { createSession } from "@/common/api";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "GeneralInfo",

  props: {
    matterName: {
      type: String,
      required: true,
    },
    inputsData: {
      type: Object,
      required: true,
    },
    sessionId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      error: {} as Error | null,
      localInputsData: {},
    };
  },

  methods: {
    async submitGeneralInfo() {
      const generalInfo = {
        matterInfo: {
          matterName: this.matterName,
          email: this.inputsData["Email"],
          insuranceCompanyName: this.inputsData["Insurance Company Name"],
          address: this.inputsData["Address"],
          adjusterName: this.inputsData["Adjuster Name"],
          adjusterSalutation: this.inputsData["Adjuster Salutation"],
          phone: this.inputsData["Phone"],
          clientName: this.inputsData["Client Name"],
          defendantName: this.inputsData["Defendant Name"],
          claimNumber: this.inputsData["Claim Number"],
          incidentDate: this.inputsData["Incident Date"],
          affordedInPip: Number(this.inputsData["Afforded in PIP"]),
        },
        damage: Number(this.inputsData["Damage"]),
      };

      this.loading = true;
      this.error = null;

      try {
        const response = await createSession(generalInfo);

        if (response.status === 201) {
          this.$router.push(`/demand-letter?session-id=${response.data.id}`);
        }
      } catch (error) {
        const message = "Failed to create session:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          this.error = error.response?.data.detail;
          console.error(message, error.response?.data)
        }

        this.loading = false;
      }
    },
  },

  mounted() {
    this.localInputsData = this.inputsData;
  },
});
</script>

<style scoped>
.inputs-grid {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(350px, 1fr) );
  gap: 24px;
  margin-top: 1rem;
}

input:invalid {
  border: 1px #dc3545 solid;
}

input:invalid:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}
</style>
