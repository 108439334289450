import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = {
  key: 0,
  class: "mt-3 text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"])),
      class: "login-form"
    }, [
      _cache[3] || (_cache[3] = _createElementVNode("label", { for: "email" }, "Email:", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "email",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.email]
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "Password:", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "password",
        class: "form-control",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.password]
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("button", {
        type: "submit",
        class: "btn btn-primary"
      }, "Log In", -1)),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}