import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container process-demand-letter-container mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "upload-form-container mb-3"
}
const _hoisted_3 = { class: "w-100 d-flex justify-content-center align-items-center gap-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_6 = {
  class: "w-100 mb-3",
  open: ""
}
const _hoisted_7 = { class: "my-3" }
const _hoisted_8 = {
  class: "w-100 mb-3",
  open: ""
}
const _hoisted_9 = { class: "ps-2 mt-3" }
const _hoisted_10 = {
  class: "w-100 mb-3",
  open: ""
}
const _hoisted_11 = { class: "mt-3" }
const _hoisted_12 = {
  class: "w-100 mb-3",
  open: ""
}
const _hoisted_13 = { class: "mt-3" }
const _hoisted_14 = {
  class: "w-100 mb-3",
  open: ""
}
const _hoisted_15 = { class: "mt-3" }
const _hoisted_16 = { class: "w-100" }
const _hoisted_17 = {
  key: 2,
  class: "fw-bold fs-5"
}
const _hoisted_18 = {
  class: "progress my-3",
  style: {"width":"100%"}
}
const _hoisted_19 = ["aria-valuenow"]
const _hoisted_20 = {
  key: 3,
  class: "processing-status-container d-flex align-items-center flex-md-column"
}
const _hoisted_21 = { class: "spinner-container" }
const _hoisted_22 = { class: "fw-bold fs-5 mb-3" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { key: 2 }
const _hoisted_26 = { key: 4 }
const _hoisted_27 = { class: "fw-bold fs-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === _ctx.GENERAL_INFO_STATUS())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.startDemandLetterGeneration && _ctx.startDemandLetterGeneration(...args)), ["prevent"])),
            class: "d-flex flex-column align-items-center gap-3"
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "fw-bold fs-5 ps-0 mb-0" }, " Please attach the demand package in PDF format and click \"Start Processing\" ", -1)),
            _createElementVNode("div", _hoisted_3, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                class: "fw-bold",
                for: "pdfFile"
              }, "Upload PDF:", -1)),
              _createElementVNode("input", {
                class: "fw-bold form-control w-25",
                required: "",
                type: "file",
                id: "pdfFile",
                accept: ".pdf",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setFileToState && _ctx.setFileToState(...args)))
              }, null, 32)
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary fw-bold align-self-center",
              type: "submit",
              disabled: !_ctx.selectedFile
            }, " Start Processing ", 8, _hoisted_4)
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === _ctx.COMPLETED_STATUS() && !_ctx.loading && _ctx.isProgressComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("details", _hoisted_6, [
            _cache[12] || (_cache[12] = _createElementVNode("summary", { class: "text-start" }, [
              _createElementVNode("h2", { class: "d-inline fs-4" }, "Demand Letter")
            ], -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ckeditor, {
                modelValue: _ctx.demandLetter,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.demandLetter) = $event)),
                editor: _ctx.editor,
                config: _ctx.editorConfig
              }, null, 8, ["modelValue", "editor", "config"])
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary fw-bold align-self-center",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSaveDemandLetter && _ctx.handleSaveDemandLetter(...args)))
            }, "Save")
          ]),
          _createElementVNode("details", _hoisted_8, [
            _cache[16] || (_cache[16] = _createElementVNode("summary", { class: "text-start" }, [
              _createElementVNode("h2", { class: "d-inline fs-4" }, "Retrieved Data")
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("details", _hoisted_10, [
                _cache[13] || (_cache[13] = _createElementVNode("summary", { class: "text-start" }, [
                  _createElementVNode("h2", { class: "d-inline fs-4" }, "Facts")
                ], -1)),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_ckeditor, {
                    modelValue: _ctx.facts,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.facts) = $event)),
                    editor: _ctx.editor,
                    config: _ctx.editorConfig
                  }, null, 8, ["modelValue", "editor", "config"])
                ])
              ]),
              _createElementVNode("details", _hoisted_12, [
                _cache[14] || (_cache[14] = _createElementVNode("summary", { class: "text-start" }, [
                  _createElementVNode("h2", { class: "d-inline fs-4" }, "Liability")
                ], -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_ckeditor, {
                    modelValue: _ctx.liability,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.liability) = $event)),
                    editor: _ctx.editor,
                    config: _ctx.editorConfig
                  }, null, 8, ["modelValue", "editor", "config"])
                ])
              ]),
              _createElementVNode("details", _hoisted_14, [
                _cache[15] || (_cache[15] = _createElementVNode("summary", { class: "text-start" }, [
                  _createElementVNode("h2", { class: "d-inline fs-4" }, "Injuries/Medicals")
                ], -1)),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_ckeditor, {
                    modelValue: _ctx.injuriesMedicals,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.injuriesMedicals) = $event)),
                    editor: _ctx.editor,
                    config: _ctx.editorConfig
                  }, null, 8, ["modelValue", "editor", "config"])
                ])
              ])
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary fw-bold align-self-center",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateRetrieval && _ctx.handleUpdateRetrieval(...args)))
            }, "Save & Regenerate ")
          ]),
          _createElementVNode("details", _hoisted_16, [
            _cache[17] || (_cache[17] = _createElementVNode("summary", { class: "text-start" }, [
              _createElementVNode("h3", { class: "d-inline fs-4" }, "Raw PDF Data")
            ], -1)),
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.ocr) = $event)),
              class: "textarea w-100 my-3"
            }, null, 512), [
              [_vModelText, _ctx.ocr]
            ]),
            _createElementVNode("button", {
              class: "btn btn-primary fw-bold align-self-center",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handleUpdateOcr && _ctx.handleUpdateOcr(...args)))
            }, "Save & Regenerate")
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isProgressComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createTextVNode(_toDisplayString(_ctx.progressText) + " ", 1),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", {
              class: "progress-bar",
              role: "progressbar",
              style: _normalizeStyle({ width: _ctx.progress + '%'}),
              "aria-valuenow": _ctx.progress,
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            }, null, 12, _hoisted_19)
          ])
        ]))
      : _createCommentVNode("", true),
    ((_ctx.status === _ctx.OCR_PROCESSING_STATUS() || _ctx.status === _ctx.RETRIEVAL_PROCESSING_STATUS() || _ctx.status === _ctx.GENERATOR_PROCESSING_STATUS()) && _ctx.isProgressComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _cache[19] || (_cache[19] = _createElementVNode("div", {
              class: "spinner-border",
              style: {"width":"3rem","height":"3rem"},
              role: "status"
            }, null, -1)),
            _createElementVNode("div", _hoisted_22, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "mb-3" }, "We are processing your documents. Please wait...", -1)),
              _createElementVNode("div", null, [
                _createTextVNode("Stage: " + _toDisplayString(_ctx.formatStatus(_ctx.status)) + ". ETA: ", 1),
                (_ctx.status === _ctx.OCR_PROCESSING_STATUS())
                  ? (_openBlock(), _createElementBlock("span", _hoisted_23, "10 minutes"))
                  : (_ctx.status === _ctx.RETRIEVAL_PROCESSING_STATUS())
                    ? (_openBlock(), _createElementBlock("span", _hoisted_24, "5 minutes"))
                    : (_ctx.status === _ctx.GENERATOR_PROCESSING_STATUS())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, "2 minutes"))
                      : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === _ctx.OCR_PROCESSING_FAILED_STATUS() || _ctx.status === _ctx.RETRIEVAL_PROCESSING_FAILED_STATUS() || _ctx.status === _ctx.GENERATOR_PROCESSING_FAILED_STATUS())
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, "Error: " + _toDisplayString(_ctx.formatStatus(_ctx.status)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}