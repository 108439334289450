import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container user-general-info-container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = {
  key: 0,
  class: "user-email fs-4 fw-medium mb-0"
}
const _hoisted_6 = {
  key: 1,
  class: "fs-4 fw-medium mb-0"
}
const _hoisted_7 = {
  key: 2,
  class: "fs-4 fw-medium mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "text-start fw-bold" }, "General Info", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.email)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Email: " + _toDisplayString(_ctx.email), 1))
            : _createCommentVNode("", true),
          (_ctx.firstName)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, "First Name: " + _toDisplayString(_ctx.firstName), 1))
            : _createCommentVNode("", true),
          (_ctx.lastName)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Last Name: " + _toDisplayString(_ctx.lastName), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}