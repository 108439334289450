<!-- @TODO: It is possible to increase scalability by implementing a BaseSettings component with shared functionality and extending it.
 for specific settings. e.g. Delete User is a shared setting and can be placed into superclass-->
<template>
  <h2 class="mb-4">Profile Settings</h2>

  <div v-if="!loading">
    <user-general-info
        :email="user?.email || ''"
        :firstName="user?.firstName || ''"
        :lastName="user?.lastName || ''">
    </user-general-info>

    <form @submit.prevent="updateUser" class="mt-5">
      <div class="mb-3 w-25 m-auto">
        <label for="exampleInputEmail1" class="form-label fw-bold">New Email</label>
        <input v-model="newEmail" type="email" class="form-control" id="exampleInputEmail1"
               aria-describedby="emailHelp">
      </div>
      <div class="mb-3 w-25 m-auto">
        <label for="exampleInputPassword1" class="form-label fw-bold">New Password</label>
        <input v-model="newPassword" type="password" class="form-control" id="exampleInputPassword1">
      </div>
      <button :disabled="!newEmail && !newPassword" class="btn btn-primary fw-bold" type="submit">Update</button>
    </form>
  </div>
</template>

<script lang="ts">
import UserGeneralInfo from "@/components/UserGenerallnfo.vue";
import { logout } from "@/common/utils";
import { getUser, updateCredentials } from "@/common/api";
import { defineComponent } from "vue";
import { IUser } from "@/types/IUser";
import { AxiosError } from "axios";

export default defineComponent({
  name: "UserProfileCommonSettings",

  components: {
    UserGeneralInfo,
  },

  props: {
    userType: String,
  },

  data() {
    return {
      user: null as IUser | null,
      newEmail: "",
      newPassword: "",
      loading: true,
    };
  },

  methods: {
    async updateUser() {
      try {
        await updateCredentials(this.newEmail || String(this.user?.email), this.newPassword);
      } catch (error) {
        const message = "Failed to update user's credentials:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
      await logout(this.$router);
    },

    async getUserInfo(): Promise<IUser | null> {
      try {
        const response = await getUser();
        const user = response.data;
        this.loading = false;
        return user;
      } catch (error) {
        const message = "Failed to get user info:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
        return null;
      }
    }
  },

  async mounted() {
    this.user = await this.getUserInfo();
  },
});
</script>
