import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "navbar sticky-top navbar-light bg-light" }
const _hoisted_2 = { class: "navbar-container container-fluid" }
const _hoisted_3 = { class: "navbar-heading-container align-items-center ms-3" }
const _hoisted_4 = { class: "navbar-control-container d-flex me-3" }
const _hoisted_5 = {
  class: "offcanvas offcanvas-end w-menu",
  "data-bs-scroll": "true",
  tabindex: "-1",
  id: "offcanvas",
  "data-bs-keyboard": "false",
  "data-bs-backdrop": "true"
}
const _hoisted_6 = { class: "offcanvas-body px-0" }
const _hoisted_7 = {
  class: "nav nav-pills flex-column mb-sm-auto mb-0 align-items-start",
  id: "menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dropdown_button = _resolveComponent("dropdown-button")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "navbar-brand text-black",
          to: "/user-profile?page=1"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Demand Letter Tool")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "offcanvas-header border-bottom" }, [
            _createElementVNode("h6", {
              class: "offcanvas-title d-none d-sm-block fw-bold",
              id: "offcanvas"
            }, "Menu"),
            _createElementVNode("button", {
              type: "button",
              class: "btn-close text-reset",
              "data-bs-dismiss": "offcanvas",
              "aria-label": "Close"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationButtons, (button, key) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: key,
                  class: "nav-item"
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(button.component), _mergeProps({ ref_for: true }, button.props), null, 16))
                ]))
              }), 128))
            ])
          ])
        ]),
        _createVNode(_component_dropdown_button, {
          "button-text": "New Session",
          handler: _ctx.handleNewSession,
          class: "me-3"
        }, null, 8, ["handler"]),
        _createVNode(_component_dropdown_button, {
          "button-text": "Sessions List",
          handler: _ctx.handleSessionList,
          class: "me-3"
        }, null, 8, ["handler"]),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          class: "navbar-toggler d-flex text-dark fw-bold border-black p-2",
          type: "button",
          "data-bs-toggle": "offcanvas",
          "aria-controls": "offcanvasScrolling",
          "data-bs-target": "#offcanvas",
          role: "button"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" }),
          _createElementVNode("i", {
            class: "bi bi-arrow-right-square-fill fs-3",
            "data-bs-toggle": "offcanvas",
            "data-bs-target": "#offcanvas"
          })
        ], -1))
      ])
    ])
  ]))
}