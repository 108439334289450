<template>
  <div class="row align-items-start">
    <div v-if="!loading" class="col-12 my-3"><h4 class="text-start">Users</h4></div>
  </div>

  <div v-if="error" class="container text-danger fw-bold text-start my-1">
    {{ error }}
    <button @click="error = null" type="button" class="btn-close btn-danger pt-0"/>
  </div>

  <div v-if="loading" class="spinner-border" role="status"></div>
  <table v-if="!loading" class="table table-hover container">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Email</th>
      <th scope="col">Name</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(user, index) in usersData" :key="user.id" class="align-items-center align-middle">
      <td>
        <div class="my-1">{{ index + 1 }}</div>
      </td>
      <td>{{ user.email }}</td>
      <td>{{ user.firstName + " " + user.lastName }}</td>
      <td v-if="user.id === userId">
        You can change your credentials
        <router-link to="/user-profile?tab=settings">here</router-link>
      </td>
      <admin-table-action-buttons
          v-else
          :record-id="user.id"
          :recreate-table-func="recreateTable"
          :set-error="setError">
      </admin-table-action-buttons>
    </tr>
    </tbody>
  </table>
  <div @click="selectAddUser" v-if="!loading" class="col my-4">
    <button class="btn btn-lg btn-outline-primary fw-bold ">Add New User</button>
  </div>
</template>

<script lang="ts">
import AdminTableActionButtons from "@/components/AdminTableActionButtons.vue";
import { getUser, getUsers } from "@/common/api";
import { defineComponent, PropType } from "vue";
import { IUser } from "@/types/IUser";
import { AxiosError } from "axios";

export default defineComponent({
  name: "AdminTable",

  components: {
    AdminTableActionButtons,
  },

  props: {
    selectAddUser: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },

  data() {
    return {
      usersData: [] as IUser[],
      loading: true,
      userId: 0,
      error: null as Error | null,
    };
  },

  methods: {
    async fetchUsers() {
      try {
        const response = await getUsers();
        this.usersData = response.data.users;
      } catch (error) {
        const message = "Failed to get users data:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }

      if (this.loading) {
        this.loading = false;
      }
    },

    async getCurrentUserId(): Promise<number> {
      try {
        const response = await getUser();
        const user = response.data;
        this.loading = false;
        return user.id;
      } catch (error) {
        const message = "Failed to get user info:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
        return 0;
      }
    },

    async setError(error: Error) {
      this.error = error;
    },
  },

  async created() {
    this.userId = await this.getCurrentUserId();
    await this.fetchUsers();
  },
});
</script>
