<template>
  <div class="login-container">
    <form @submit.prevent="login" class="login-form">
      <label for="email">Email:</label>
      <input type="email" class="form-control" v-model="email" required/>

      <label for="password">Password:</label>
      <input type="password" class="form-control" v-model="password" required/>

      <button type="submit" class="btn btn-primary">Log In</button>

      <div v-if="error" class="mt-3 text-danger">{{error}}</div>
    </form>

  </div>
</template>

<script lang="ts">
import { login } from "@/common/api";
import TokenService from "@/services/TokenService";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "LoginView",

  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },

  methods: {
    async login() {
      try {
        const email = this.email.trim();

        const tokenResponse = await login(email, this.password);
        const token = tokenResponse.data.token;

        if (token) {
          TokenService.setToken(token);
          this.$router.push("/user-profile?page=1");
        } else {
          console.error("Token not received from login response");
        }
      } catch (error) {
        const message = "Failed to log in:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data);
        }

        this.error = "Invalid Email or Password";
      }
    },

    async redirectIfLoggedIn() {
      const isValid = await TokenService.validateToken();
      if (isValid) {
        this.$router.push("/user-profile?page=1");
      }
    },
  },

  async mounted() {
    await this.redirectIfLoggedIn();
  },
});
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f5f5f5, #e0e0e0);
}

.login-form {
  max-width: 300px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input {
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Размер шрифта */
}
</style>
