<template>
  <navbar-component/>
  <main v-if="!loading" class="container-xxl demand-letter-container pt-4 pb-4">
    <matter-name :matter-name="matterName" :session-id="sessionId"/>
    <demand-letter-process-section
        :session-data="sessionData"
        :status="sessionData.status"
        :sessionId="sessionId"
        :handle-fetch-session-info="fetchSessionInfo">
    </demand-letter-process-section>
    <general-info
        v-if="isGeneralInfoFetched"
        :inputsData="generalInfoInputs"
        :sessionId="sessionId"
        :sessionStatus="sessionData.status"
        :matterName="matterName"
    />
  </main>

  <div v-else class="container-xxl demand-letter-container mt-5">
    <div class="spinner-border" style="width: 15rem; height: 15rem;" role="status"></div>
  </div>
</template>

<script lang="ts">
import NavbarComponent from "@/components/Navbar.vue"
import DemandLetterProcessSection from "@/components/DemandLetterProcessSection.vue";
import GeneralInfo from "@/components/GeneralInfo.vue";
import { getSession } from "@/common/api";
import MatterName from "@/components/MatterName.vue";
import generalInfoMixin from "@/mixins/generalInfoMixin";
import { defineComponent } from "vue";
import { AxiosError } from "axios";
import { ISession } from "@/types/ISession";

export default defineComponent({
  name: "DemandLetterView",

  components: {
    MatterName,
    NavbarComponent,
    GeneralInfo,
    DemandLetterProcessSection,
  },

  mixins: [generalInfoMixin],

  props: {
    sessionId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: true,
      sessionData: {
        generalInfo: {},
        demandLetter: "",
      } as ISession,
    };
  },

  methods: {
    async fetchSessionInfo(showLoading = true) {
      if (showLoading) {
        this.loading = true;
      }

      try {
        const response = await getSession(this.sessionId);

        if (response.status === 200) {
          this.sessionData = response.data;

          this.loading = false;

          if (this.sessionData.generalInfo) {
            this.setGeneralInfo(this.sessionData.generalInfo);
          }
        }
      } catch (error) {
        const message = "Failed to get session's data:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },
  },

  async mounted() {
    await this.fetchSessionInfo();
  },
});
</script>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

p {
  margin: 0;
  font-size: 16px;
  color: #333;
}
</style>
