import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row align-items-start" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 my-3"
}
const _hoisted_3 = {
  key: 0,
  class: "container text-danger fw-bold text-start my-1"
}
const _hoisted_4 = {
  key: 1,
  class: "spinner-border",
  role: "status"
}
const _hoisted_5 = {
  key: 2,
  class: "table table-hover container"
}
const _hoisted_6 = { class: "my-1" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_admin_table_action_buttons = _resolveComponent("admin-table-action-buttons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
            _createElementVNode("h4", { class: "text-start" }, "Users", -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.error) + " ", 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = null)),
            type: "button",
            class: "btn-close btn-danger pt-0"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("table", _hoisted_5, [
          _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { scope: "col" }, "#"),
              _createElementVNode("th", { scope: "col" }, "Email"),
              _createElementVNode("th", { scope: "col" }, "Name"),
              _createElementVNode("th", { scope: "col" }, "Action")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersData, (user, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: user.id,
                class: "align-items-center align-middle"
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(index + 1), 1)
                ]),
                _createElementVNode("td", null, _toDisplayString(user.email), 1),
                _createElementVNode("td", null, _toDisplayString(user.firstName + " " + user.lastName), 1),
                (user.id === _ctx.userId)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                      _cache[4] || (_cache[4] = _createTextVNode(" You can change your credentials ")),
                      _createVNode(_component_router_link, { to: "/user-profile?tab=settings" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("here")
                        ])),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_admin_table_action_buttons, {
                      key: 1,
                      "record-id": user.id,
                      "recreate-table-func": _ctx.recreateTable,
                      "set-error": _ctx.setError
                    }, null, 8, ["record-id", "recreate-table-func", "set-error"]))
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectAddUser && _ctx.selectAddUser(...args))),
          class: "col my-4"
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("button", { class: "btn btn-lg btn-outline-primary fw-bold" }, "Add New User", -1)
        ])))
      : _createCommentVNode("", true)
  ], 64))
}