import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container-xxl demand-letter-container pt-4 pb-4"
}
const _hoisted_2 = {
  key: 1,
  class: "container-xxl demand-letter-container mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar_component = _resolveComponent("navbar-component")!
  const _component_matter_name = _resolveComponent("matter-name")!
  const _component_demand_letter_process_section = _resolveComponent("demand-letter-process-section")!
  const _component_general_info = _resolveComponent("general-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navbar_component),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
          _createVNode(_component_matter_name, {
            "matter-name": _ctx.matterName,
            "session-id": _ctx.sessionId
          }, null, 8, ["matter-name", "session-id"]),
          _createVNode(_component_demand_letter_process_section, {
            "session-data": _ctx.sessionData,
            status: _ctx.sessionData.status,
            sessionId: _ctx.sessionId,
            "handle-fetch-session-info": _ctx.fetchSessionInfo
          }, null, 8, ["session-data", "status", "sessionId", "handle-fetch-session-info"]),
          (_ctx.isGeneralInfoFetched)
            ? (_openBlock(), _createBlock(_component_general_info, {
                key: 0,
                inputsData: _ctx.generalInfoInputs,
                sessionId: _ctx.sessionId,
                sessionStatus: _ctx.sessionData.status,
                matterName: _ctx.matterName
              }, null, 8, ["inputsData", "sessionId", "sessionStatus", "matterName"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-border",
            style: {"width":"15rem","height":"15rem"},
            role: "status"
          }, null, -1)
        ])))
  ], 64))
}