import {IGeneralInfo} from "@/types/IGeneralInfo";
import {defineComponent} from "vue";

export default defineComponent({
  data() {
    return {
      matterName: "",
      generalInfoInputs: {
        "Email": "",
        "Insurance Company Name": "",
        "Address": "",
        "Adjuster Name": "",
        "Adjuster Salutation": "",
        "Phone": "",
        "Client Name": "",
        "Defendant Name": "",
        "Claim Number": "",
        "Incident Date": "",
        "Afforded in PIP": "",
        "Damage": "",
      },
      isGeneralInfoFetched: false,
    };
  },

  methods: {
    setGeneralInfo(generalInfo: IGeneralInfo) {
      const matterInfo = generalInfo.matterInfo;
      const damage = generalInfo.damage;

      this.isGeneralInfoFetched = true;

      this.matterName = matterInfo.matterName.trim();
      this.generalInfoInputs["Email"] = matterInfo.email.trim();
      this.generalInfoInputs["Insurance Company Name"] = matterInfo.insuranceCompanyName.trim();
      this.generalInfoInputs["Address"] = matterInfo.address.trim();
      this.generalInfoInputs["Adjuster Name"] = matterInfo.adjusterName.trim();
      this.generalInfoInputs["Adjuster Salutation"] = matterInfo.adjusterSalutation.trim();
      this.generalInfoInputs["Phone"] = matterInfo.phone.trim();
      this.generalInfoInputs["Client Name"] = matterInfo.clientName.trim();
      this.generalInfoInputs["Defendant Name"] = matterInfo.defendantName.trim();
      this.generalInfoInputs["Claim Number"] = matterInfo.claimNumber.trim();
      this.generalInfoInputs["Incident Date"] = matterInfo.incidentDate.trim();
      this.generalInfoInputs["Afforded in PIP"] = (matterInfo.affordedInPip === null ? "" : String(matterInfo.affordedInPip).trim());
      this.generalInfoInputs["Damage"] = (damage === null ? "" : String(damage).trim());
    }
  },
});
