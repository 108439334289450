import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "container general-info-container mb-3" }
const _hoisted_2 = ["open"]
const _hoisted_3 = {
  key: 0,
  class: "text-center fw-bold fs-5 ps-0 mb-0 mt-3"
}
const _hoisted_4 = {
  id: "generalInfoForm",
  class: "inputs-grid"
}
const _hoisted_5 = ["for"]
const _hoisted_6 = ["onUpdate:modelValue", "disabled", "id"]
const _hoisted_7 = {
  key: 1,
  class: "d-flex flex-column mt-3"
}
const _hoisted_8 = {
  key: 1,
  class: "btn btn-primary fw-bold fs-6 col-2 mt-3 align-self-center",
  type: "button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("details", {
        class: "align-self-start w-100 text-start",
        open: !_ctx.sessionId
      }, [
        _cache[2] || (_cache[2] = _createElementVNode("summary", null, [
          _createElementVNode("h3", { class: "text-start fs-4 d-inline" }, "General Case Info")
        ], -1)),
        (!_ctx.sessionId)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, "If necessary, fill in the blanks and change the incorrect data, then click \"Continue\""))
          : _createCommentVNode("", true),
        _createElementVNode("form", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localInputsData, (_, key) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "d-flex flex-column"
            }, [
              _createElementVNode("label", {
                class: "pb-2 ps-0 fs-5 text-start fw-medium",
                for: key
              }, _toDisplayString(key), 9, _hoisted_5),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": ($event: any) => ((_ctx.localInputsData[key]) = $event),
                class: "p-2 fw-medium form-control",
                required: "",
                pattern: "\\S+.*",
                type: "text",
                disabled: Boolean(_ctx.sessionId),
                id: key
              }, null, 8, _hoisted_6), [
                [_vModelText, _ctx.localInputsData[key]]
              ])
            ]))
          }), 128))
        ]),
        (!_ctx.sessionId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    form: "generalInfoForm",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitGeneralInfo && _ctx.submitGeneralInfo(...args))),
                    class: "btn btn-primary fw-bold fs-6 col-2 mt-3 align-self-center"
                  }, "Continue "))
                : (_openBlock(), _createElementBlock("button", _hoisted_8, _cache[1] || (_cache[1] = [
                    _createElementVNode("span", {
                      class: "spinner-border spinner-border-sm me-3",
                      "aria-hidden": "true"
                    }, null, -1),
                    _createElementVNode("span", { role: "status" }, "Loading...", -1)
                  ])))
            ]))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2)
    ])
  ]))
}