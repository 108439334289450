import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "container matter-info-container" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "container d-flex justify-content-start" }
const _hoisted_4 = { class: "text-start row col-4 col-lg-3 col-xl-2 col-xxl-2 col-md-4 col-sm-4 col-" }
const _hoisted_5 = { class: "row col-4 col-lg-2 col-xl-2 col-xxl-2 col-md-3 col-sm-4 ms-5" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 1,
  class: "btn btn-primary fw-bold",
  type: "button",
  disabled: ""
}
const _hoisted_8 = {
  key: 0,
  class: "text-start fw-bold mb-4 mt-2 text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "text-start fw-bold mb-4" }, "Enter the Matter name from Salesforce, e.g., MAT-12345678910:", -1)),
        _createElementVNode("form", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.matterName) = $event)),
              type: "text",
              class: "form-control fw-medium border-black",
              placeholder: "MAT-12345678910"
            }, null, 512), [
              [_vModelText, _ctx.matterName]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
                  disabled: !_ctx.matterName,
                  class: "btn btn-primary fw-bold"
                }, "Submit ", 8, _hoisted_6))
              : (_openBlock(), _createElementBlock("button", _hoisted_7, _cache[2] || (_cache[2] = [
                  _createElementVNode("span", {
                    class: "spinner-border spinner-border-sm me-3",
                    "aria-hidden": "true"
                  }, null, -1),
                  _createElementVNode("span", { role: "status" }, "Loading...", -1)
                ])))
          ])
        ])
      ]),
      (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}