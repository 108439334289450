<template>
  <div class="mb-2 fw-bold">Delete this user?</div>
  <button @click="deleteUser" type="button" class="btn btn-danger mx-2">
    Delete
  </button>
  <button @click="deactivateComponentFunc()" type="button" class="btn btn-primary mx-2">
    Cancel
  </button>
</template>

<script lang="ts">
import { deleteUser } from "@/common/api";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

export default defineComponent({
  name: "AdminTableDeleteUserButton.vue",

  props: {
    recordId: {
      type: Number,
      required: true,
    },
    deactivateComponentFunc: {
      type: Function,
      required: true,
    },
    reloadAdminTable: {
      type: Function,
      required: true,
    },
    recreateTableFunc: {
      type: Function,
      required: true,
    },
    setError: {
      type: Function,
      required: true,
    },
  },

  methods: {
    async deleteUser() {
      try {
        await deleteUser(this.recordId);
        this.deactivateComponentFunc();
        this.recreateTableFunc();
      } catch (error) {
        this.deactivateComponentFunc();
        const message = "Failed to delete user:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },
  },
});
</script>
