<template>
  <!-- @TODO: Display status_text instead instead of hardcoded text?   -->
  <div class="container process-demand-letter-container mb-3">

    <div v-if="status === GENERAL_INFO_STATUS()" class="upload-form-container mb-3">
      <form
          @submit.prevent="startDemandLetterGeneration"
          class="d-flex flex-column align-items-center gap-3">

        <p class="fw-bold fs-5 ps-0 mb-0">
          Please attach the demand package in PDF format and click "Start Processing"
        </p>

        <div class="w-100 d-flex justify-content-center align-items-center gap-2">
          <label class="fw-bold" for="pdfFile">Upload PDF:</label>
          <input class="fw-bold form-control w-25" required type="file" id="pdfFile" accept=".pdf"
                 @change="setFileToState"/>
        </div>

        <button class="btn btn-primary fw-bold align-self-center"
                type="submit"
                :disabled="!selectedFile">
          Start Processing
        </button>
      </form>
    </div>

    <div class="d-flex flex-column" v-if="status === COMPLETED_STATUS() && !loading && isProgressComplete">

      <details class="w-100 mb-3" open>
        <summary class="text-start">
          <h2 class="d-inline fs-4">Demand Letter</h2>
        </summary>

        <div class="my-3">
          <ckeditor
              v-model="demandLetter"
              :editor="editor"
              :config="editorConfig"
          />
        </div>
        <button class="btn btn-primary fw-bold align-self-center" @click="handleSaveDemandLetter">Save</button>
      </details>

      <details class="w-100 mb-3" open>
        <summary class="text-start">
          <h2 class="d-inline fs-4">Retrieved Data</h2>
        </summary>

        <div class="ps-2 mt-3">
          <details class="w-100 mb-3" open>
            <summary class="text-start">
              <h2 class="d-inline fs-4">Facts</h2>
            </summary>

            <div class="mt-3">
              <ckeditor
                  v-model="facts"
                  :editor="editor"
                  :config="editorConfig"
              />
            </div>
          </details>

          <details class="w-100 mb-3" open>
            <summary class="text-start">
              <h2 class="d-inline fs-4">Liability</h2>
            </summary>

            <div class="mt-3">
              <ckeditor
                  v-model="liability"
                  :editor="editor"
                  :config="editorConfig"
              />
            </div>
          </details>

          <details class="w-100 mb-3" open>
            <summary class="text-start">
              <h2 class="d-inline fs-4">Injuries/Medicals</h2>
            </summary>

            <div class="mt-3">
              <ckeditor
                  v-model="injuriesMedicals"
                  :editor="editor"
                  :config="editorConfig"
              />
            </div>
          </details>
        </div>

        <button class="btn btn-primary fw-bold align-self-center" @click="handleUpdateRetrieval">Save & Regenerate
        </button>
      </details>

      <details class="w-100">
        <summary class="text-start">
          <h3 class="d-inline fs-4">Raw PDF Data</h3>
        </summary>

        <textarea v-model="ocr" class="textarea w-100 my-3"/>

        <button class="btn btn-primary fw-bold align-self-center" @click="handleUpdateOcr">Save & Regenerate</button>
      </details>
    </div>

    <div v-if="!isProgressComplete" class="fw-bold fs-5">
      {{ progressText }}
      <div class="progress my-3" style="width: 100%;">
        <div class="progress-bar"
             role="progressbar"
             :style="{ width: progress + '%'}"
             :aria-valuenow="progress"
             aria-valuemin="0"
             aria-valuemax="100">
        </div>
      </div>
    </div>

    <div
        v-if="(status === OCR_PROCESSING_STATUS() || status === RETRIEVAL_PROCESSING_STATUS() || status === GENERATOR_PROCESSING_STATUS()) && isProgressComplete"
        class="processing-status-container d-flex align-items-center flex-md-column">
      <div class="spinner-container">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
        <div class="fw-bold fs-5 mb-3">
          <div class="mb-3">We are processing your documents. Please wait...</div>
          <div>Stage: {{ formatStatus(status) }}. ETA:
            <span v-if="status === OCR_PROCESSING_STATUS()">10 minutes</span>
            <span v-else-if="status === RETRIEVAL_PROCESSING_STATUS()">5 minutes</span>
            <span v-else-if="status === GENERATOR_PROCESSING_STATUS()">2 minutes</span>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="status === OCR_PROCESSING_FAILED_STATUS() || status === RETRIEVAL_PROCESSING_FAILED_STATUS() || status === GENERATOR_PROCESSING_FAILED_STATUS()">
      <div class="fw-bold fs-5">Error: {{ formatStatus(status) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { getMappedStatus } from "@/common/utils";
import { reprocessGenerator, reprocessOcr, reprocessRetrieval, uploadDemandPackage } from "@/common/api";
import {
  COMPLETED_STATUS,
  GENERAL_INFO_STATUS,
  GENERATOR_PROCESSING_FAILED_STATUS,
  GENERATOR_PROCESSING_STATUS,
  OCR_PROCESSING_FAILED_STATUS,
  OCR_PROCESSING_STATUS,
  RESEND_REQUEST_TIMEOUT,
  RETRIEVAL_PROCESSING_FAILED_STATUS,
  RETRIEVAL_PROCESSING_STATUS,
  StatusKey
} from "@/common/constants";
import { defineComponent } from "vue";
import { AxiosError } from "axios";

import {
  Bold,
  ClassicEditor,
  Essentials,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Italic,
  Link,
  List,
  Markdown,
  Paragraph,
  Undo
} from "ckeditor5";
import 'ckeditor5/ckeditor5.css';
import { Ckeditor } from "@ckeditor/ckeditor5-vue";

export default defineComponent({
  components: {
    Ckeditor,
  },

  props: {
    status: {
      type: String as () => StatusKey,
      required: true,
    },
    sessionData: {
      type: Object,
      required: true,
    },
    handleFetchSessionInfo: {
      type: Function,
      required: true,
    },
    salutation: {
      type: String,
    },
    sessionId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      selectedFile: {} as File | undefined,
      ocr: "",
      facts: "",
      liability: "",
      injuriesMedicals: "",
      demandLetter: "",
      progress: 0,
      progressStartTime: 0,
      intervalRequest: null as ReturnType<typeof setTimeout> | null,
      isProgressComplete: true,
      progressText: "Uploading your documents...",

      editor: ClassicEditor,
      editorConfig: {
        plugins: [Markdown, Bold, Essentials, Italic, Paragraph, Undo, Heading, FontFamily, FontSize, FontColor, Link, List],
        toolbar: {
          items: [
            'undo', 'redo',
            '|',
            'heading',
            '|',
            'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
            '|',
            'bold', 'italic',
            '|',
            'bulletedList', 'numberedList',
            '|',
            'link',
          ],
          shouldNotGroupWhenFull: false
        },
      }
    };
  },

  methods: {
    RETRIEVAL_PROCESSING_FAILED_STATUS() {
      return RETRIEVAL_PROCESSING_FAILED_STATUS;
    },

    OCR_PROCESSING_FAILED_STATUS() {
      return OCR_PROCESSING_FAILED_STATUS;
    },

    GENERAL_INFO_STATUS() {
      return GENERAL_INFO_STATUS;
    },

    COMPLETED_STATUS() {
      return COMPLETED_STATUS;
    },

    GENERATOR_PROCESSING_STATUS() {
      return GENERATOR_PROCESSING_STATUS;
    },

    RETRIEVAL_PROCESSING_STATUS() {
      return RETRIEVAL_PROCESSING_STATUS;
    },

    OCR_PROCESSING_STATUS() {
      return OCR_PROCESSING_STATUS;
    },

    GENERATOR_PROCESSING_FAILED_STATUS() {
      return GENERATOR_PROCESSING_FAILED_STATUS;
    },

    async startDemandLetterGeneration() {
      this.loading = true;

      this.startProgress();

      const formData = new FormData();
      if (this.selectedFile instanceof File) {
        formData.append("demand_package", this.selectedFile);
      }

      try {
        await uploadDemandPackage(this.sessionId, formData);
        await this.handleFetchSessionInfo();
      } catch (error) {
        const message = "Failed to upload demand package:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      } finally {
        this.loading = false;
      }
    },

    startProgress() {
      this.progressStartTime = Date.now();
      localStorage.setItem(`progressStartTime-${this.sessionId}`, String(this.progressStartTime));
      this.updateProgress();
    },

    updateProgress() {
      this.isProgressComplete = false;

      const firstPhaseDuration = 50000;
      const secondPhaseDuration = 10000;
      const midPoint = 60;

      const updateProgress = () => {
        const elapsedTime = Date.now() - this.progressStartTime;

        if (elapsedTime <= firstPhaseDuration) {
          this.progress = (elapsedTime / firstPhaseDuration) * midPoint;
        } else {
          const timeInSecondPhase = elapsedTime - firstPhaseDuration;
          this.progress = midPoint + (timeInSecondPhase / secondPhaseDuration) * (100 - midPoint);
        }

        if (this.progress < 100) {
          requestAnimationFrame(updateProgress);
        }

        if (this.progress >= 100 || this.status !== this.GENERAL_INFO_STATUS()) {
          this.progress = 100;
          this.loading = false;
          localStorage.removeItem(`progressStartTime-${this.sessionId}`);

          this.progressText = "Done!";
          setTimeout(() => {
            this.isProgressComplete = true;
          }, 3000);
        }
      };

      requestAnimationFrame(updateProgress);
    },

    setFileToState(event: Event) {
      this.selectedFile = (event.target as HTMLInputElement).files?.[0];
    },

    async startPolling(interval: number) {
      this.intervalRequest = setInterval(async () => {
        await this.handleFetchSessionInfo(false);

        if (this.status === this.COMPLETED_STATUS()) {
          this.setFields();
          this.cancelPolling();
          return;
        }
      }, interval);
    },

    setFields() {
      this.ocr = this.sessionData.ocr;
      this.facts = this.sessionData.facts;
      this.liability = this.sessionData.liability;
      this.injuriesMedicals = this.sessionData.injuriesMedicals;
      this.demandLetter = this.sessionData.demandLetter;
    },

    async handleUpdateOcr() {
      try {
        const response = await reprocessOcr({id: this.sessionId, ocr: this.ocr});

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo(false);
          await this.startPolling(RESEND_REQUEST_TIMEOUT);
        }
      } catch (error) {
        const message = "Failed to reprocess OCR:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    async handleUpdateRetrieval() {
      const retrievalMessage = {
        id: this.sessionId,
        facts: this.facts,
        liability: this.liability,
        injuriesMedicals: this.injuriesMedicals
      }

      try {
        const response = await reprocessRetrieval(retrievalMessage);

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo(false);
          await this.startPolling(RESEND_REQUEST_TIMEOUT);
        }
      } catch (error) {
        const message = "Failed to reprocess retrieval:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    async handleSaveDemandLetter() {
      try {
        const response = await reprocessGenerator({id: this.sessionId, demandLetter: this.demandLetter});

        if (response.status === 200) {
          this.loading = false;
          await this.handleFetchSessionInfo();
        }
      } catch (error) {
        const message = "Failed to reprocess generator:";
        if (error instanceof Error) {
          console.error(message, error.message);
        } else if (error instanceof AxiosError) {
          console.error(message, error.response?.data)
        }
      }
    },

    cancelPolling() {
      if (this.intervalRequest) {
        clearInterval(this.intervalRequest);
        this.intervalRequest = null;
      }
    },

    formatStatus(status: StatusKey) {
      return getMappedStatus(status);
    }
  },

  async created() {
    if (this.status === this.OCR_PROCESSING_STATUS() || this.status === this.RETRIEVAL_PROCESSING_STATUS() || this.status === this.GENERATOR_PROCESSING_STATUS()) {
      await this.startPolling(RESEND_REQUEST_TIMEOUT);
    } else {
      this.setFields();
    }
  },

  mounted() {
    const savedStartTime = localStorage.getItem(`progressStartTime-${this.sessionId}`);

    if (savedStartTime) {
      this.progressStartTime = parseInt(savedStartTime, 10);
      this.updateProgress();
    }
  },

  beforeUnmount() {
    this.cancelPolling();
  },
});
</script>

<style scoped>
.textarea {
  height: 600px;
}
</style>

<style>
.ck-editor__editable {
  max-height: 600px;
  overflow-y: auto;
}
</style>
