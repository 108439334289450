<template>
  <div v-if="matterName" class="container mb-3">
    <p class="text-start fs-4 fw-medium">Matter Name:
      {{ matterName }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MatterName",

  props: {
    matterName: {
      type: String,
      required: true,
    },
    sessionId: {
      type: Number,
      required: false,
    },
  },
});
</script>
