import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.activeComponent)
    ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.selectAction('AdminTableChangeEmailButton'); _ctx.setError(null);}),
          type: "button",
          class: "btn btn-primary btn-sm"
        }, "Change Email "),
        _cache[3] || (_cache[3] = _createElementVNode("span", { class: "border-start border-primary mx-2" }, null, -1)),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.selectAction('AdminTableChangePasswordButton'); _ctx.setError(null);}),
          type: "button",
          class: "btn btn-primary btn-sm"
        }, "Change Password "),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "border-start border-primary mx-2" }, null, -1)),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.selectAction('AdminTableDeleteUserButton'); _ctx.setError(null);}),
          type: "button",
          class: "btn btn-danger btn-sm"
        }, "Delete User ")
      ]))
    : (_openBlock(), _createElementBlock("td", _hoisted_2, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeComponent), {
          setError: _ctx.setError,
          recordId: _ctx.recordId,
          deactivateComponentFunc: _ctx.deactivateAction,
          recreateTableFunc: _ctx.recreateTableFunc
        }, null, 8, ["setError", "recordId", "deactivateComponentFunc", "recreateTableFunc"]))
      ]))
}